import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
// Assets
import { Accordion } from '@mantine/core';
import classes from './Faq.module.css';
import Markdown from 'react-markdown';


export default function Faq() {
  return (
    <Wrapper id="faq">
      <div className="lightBg">
        <div className="container">
        <HeaderInfo>
            <h1 className="font40 extraBold">Le Coin des Réponses 🎓</h1>
            <p className="font13">
              <br />
              <br />
              Découvrez les réponses aux questions les plus fréquentes sur l'installation photovoltaïque, la rentabilité, et les aides financières pour optimiser votre projet solaire avec Mutualix.
            </p>
          </HeaderInfo>

      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="1">
          <Accordion.Control> <h4> Quelles aides financières pour maximiser la rentabilité de mon installation ? </h4> </Accordion.Control>
          <Accordion.Panel>
            <Markdown children="

Pour optimiser la rentabilité de votre projet solaire, plusieurs aides financières sont disponibles :\
\
• **Réduction de la TVA :**\
Profitez d'une TVA réduite à 10% au lieu de 20% pour les installations photovoltaïques jusqu'à 3 kWc.
\
\
• **Prime à l'autoconsommation :**\
Obtenez une subvention entre 700 et 2 000 euros, selon la puissance installée, si vous choisissez l'autoconsommation avec revente du surplus d'énergie solaire.
\
\
• **Revenu sur le surplus :**\
Vendez l'excédent d'électricité produit par vos panneaux solaires avec un contrat de rachat sur une période de 20 ans.

">
</Markdown></Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="2">
          <Accordion.Control> <h4>Suis-je éligible aux aides solaire photovoltaïque ?</h4></Accordion.Control>
          <Accordion.Panel><Markdown children="
Pour être éligible aux aides et à l'installation de panneaux solaires, vous devez remplir les critères suivants :\
\
◦ **Toiture en bon état**, sans amiante.
\
◦ **Propriétaire** d'une maison individuelle connectée au réseau électrique.
\
◦ **Surface suffisante** de toiture d'au moins 25 mètres carrés.
\
\
Si ces conditions sont respectées, votre installation photovoltaïque pourrait bénéficier des aides disponibles.
          ">
</Markdown></Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="3">
          <Accordion.Control><h4>Où installez-vous des panneaux photovoltaïques en France ?</h4>
          </Accordion.Control>
          <Accordion.Panel>
            <Markdown children="
Nous réalisons des installations photovoltaïques **partout en France** grâce à nos partenaires certifiés RGE et qualiPV.\
Cette couverture nationale, couplée à des normes strictes de qualité, garantit une installation fiable et conforme. Notre réseau de partenaires,\
soigneusement sélectionné, assure une prestation de qualité où que vous soyez.
            ">
              
            </Markdown>
       </Accordion.Panel>
       </Accordion.Item>

        <Accordion.Item className={classes.item} value="4">
          <Accordion.Control><h4>Pourquoi le taux d'autoconsommation est-il essentiel ?</h4>
          </Accordion.Control>
          <Accordion.Panel>
            <Markdown children="
Le taux d’autoconsommation détermine la part de l’énergie solaire produite par vos panneaux photovoltaïques que vous consommez en direct.\
Un taux élevé d’autoconsommation **améliore la rentabilité de votre installation.**\
L’électricité produite est gratuite et diminue votre dépendance au réseau.\
Pour maximiser ce taux et vos économies, il est crucial d'**utiliser notre calculateur solaire** pour une simulation précise et adaptée.
            ">
              
          </Markdown>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="5">
          <Accordion.Control><h4>Quelles garanties protègent mon installation solaire ?</h4></Accordion.Control>
          <Accordion.Panel>
            <Markdown children="
Chaque composant de votre installation photovoltaïque est couvert par des garanties spécifiques :\
\
• **Onduleur : Garantie 25 ans. Pièces et main d'oeuvre.**\
• **Panneaux solaires : Garantie 25 ans. Pièces et main d'oeuvre.** La performance est assurée et garantie à 90% de la capacité initiale après 25 ans.\
\
En plus, votre maison est protégée par une garantie décennale couvrant tout problème lié à l'installation.
            ">
              
            </Markdown>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item className={classes.item} value="6">
          <Accordion.Control><h4>Quel est le délai pour l'installation de mes panneaux photovoltaïques ?</h4>
          </Accordion.Control>
          <Accordion.Panel>
            <Markdown children="
Le délai d'installation de votre système photovoltaïque dépend de votre localisation, mais il faut généralement compter entre **4 et 8 semaines** après la signature du contrat.\
L'installation sur votre toit elle-même prend généralement **un à deux jours sur place.**
            ">
              
            </Markdown> 
            </Accordion.Panel>
       </Accordion.Item>

        <Accordion.Item className={classes.item} value="7">
          <Accordion.Control><h4>Travaillez-vous avec des sous-traitants pour l'installation de panneaux solaires ?</h4>
          </Accordion.Control>
          <Accordion.Panel>
              <Markdown children="
 Oui, nous collaborons avec des **artisans spécialistes des installation photovoltaïque.** Et ce, afin de vous garantir une installation photovoltaïque de haute qualité.\
Les compétences spécifiques nécessaires, comme le travail en hauteur et le respect des normes électriques, sont assurées par des artisans expérimentés, tandis que **Mutualix se concentre sur le perfectionnement de son calculateur solaire**.
              ">
             
              </Markdown> 
          </Accordion.Panel>
       </Accordion.Item>

        <Accordion.Item className={classes.item} value="8">
          <Accordion.Control><h4> Y a-t-il des coûts cachés dans votre offre d'installation photovoltaïque ?</h4>
          </Accordion.Control>
          <Accordion.Panel>
            <Markdown children="
**Non**, notre offre est totalement transparente. Elle inclut tous les éléments nécessaires : le matériel (panneaux solaires, onduleur, supports), la main-d'œuvre (conception, installation, gestion administrative), ainsi que le transport.\
**Aucun frais caché ne viendra s'ajouter à votre installation.\
Le simulateur est 100 % gratuit et sans engagement**
            ">
             
            </Markdown>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <HeaderInfo>

          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  margin-bottom: 0px;
`;


const HeaderInfo = styled.div`
  margin-bottom: 30px;
 padding-top: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

