import React from "react";
import styled from "styled-components";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"
import { MantineProvider} from '@mantine/core';

import ArticleBox from "../components/Elements/ArticleBox"


import AnimTaxes from "../assets/animations/anim-taxes.json"; 
import AnimMoney from "../assets/animations/anim-money.json";
import AnimTime from "../assets/animations/anim-lifespan.json";
import AnimRecycle from "../assets/animations/anim-recycle.json";
import AnimCO2 from "../assets/animations/anim-carbon-foot.json";
import AnimPrice from "../assets/animations/anim-cart.json";
import Anim404 from "../assets/animations/anim-403.json";

import AnimCoins from "../assets/animations/anim-coins.json";
import AnimGreenEnergy from "../assets/animations/anim-green-energy.json";
import AnimTeacher from "../assets/animations/anim-teacher.json";
import AnimRenewable from "../assets/animations/anim-renewable.json";
import AnimLaw from "../assets/animations/anim-law.json";
import AnimSolar from "../assets/animations/anim-satelitte.json";


import { Routes, Route, Outlet, Link } from "react-router-dom";
export default function Blog() {
  return (
    <>
      <TopNavbar />
      
      <Wrapper id="blog">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Pour aller plus loin !</h1>
              <p className="font13">
                <br />
                Découvrez tous les avantages de l'énergie solaire ! Économies sur vos factures, indépendance énergétique, réduction de votre empreinte carbone… Le photovoltaïque, c’est tout ça et bien plus. Explorez les bénéfices concrets pour votre maison et votre avenir.
              </p>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">

                <ArticleBox
                  title="Combien de panneaux dois-je installer ?"
                  text="Le nombre de panneaux solaires dépend de la consommation horaire, de l'ensoleillement et du toit, que le calculateur Mutualix estime précisément."
                  link="/blog/article/combien-de-panneaux-solaires-installer"
                  anim={AnimSolar}
                />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ArticleBox
                  title="Quelles sont les démarches administratives pour une installation photovoltaïque"
                  text="L'installation de panneaux solaires en France nécessite des démarches administratives comme la déclaration de travaux, et peut bénéficier d'aides financières comme la prime à l'autoconsommation."
                  link="/blog/article/les-demarches-administratives-installation-photovoltaique"
                  anim={AnimLaw}
                />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ArticleBox
                  title="Pourquoi investir dans une installation photovoltaïque ?"
                  text="Investir dans le photovoltaïque permet de réduire vos factures d’électricité, de vous protéger contre la hausse des prix de l’énergie, de profiter d’aides financières, tout en contribuant à la transition écologique."
                  link="/blog/article/pourquoi-investir-installation-photovoltaique"
                  anim={AnimCoins}
                />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">

                <ArticleBox
                  title="Peut-on vraiment dire qu'un panneau solaire est recyclable ?"
                  text="Le recyclage des panneaux solaires en France, géré par des acteurs comme Soren et Veolia, permet de récupérer jusqu'à 90 % des matériaux, réduisant ainsi les déchets électroniques et contribuant à la transition énergétique durable. "
                  link="/blog/article/panneau-solaire-recyclage"
                  anim={AnimGreenEnergy}
                />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">

                <ArticleBox
                  title="Comment ça marche un panneau photovoltaïque ?"
                  text="Les panneaux photovoltaïques transforment la lumière solaire en électricité via l'effet photovoltaïque. Le courant continu produit est converti en courant alternatif par un onduleur pour alimenter la maison ou vendre l'excédent."
                  link="/blog/article/comment-ca-marche-un-panneau-photovoltaique"
                  anim={AnimTeacher}
                />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ArticleBox
                  title="Quel avenir pour la transition écologique en France ?"
                  text="Cet article explore l'évolution des énergies renouvelables en France, leur croissance rapide, les défis à surmonter et les perspectives pour un futur énergétique plus durable et indépendant."
                  link="/blog/article/avenir-transition-ecologique-france"
                  anim={AnimRenewable}
                />
            </div>
          </div>
          <HeaderInfo>
              <h1 className="font40 extraBold"></h1>
              <p className="font13">
                <br />
              </p>
            </HeaderInfo>
          </div>
        </div>
      </Wrapper>

      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
