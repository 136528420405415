import React from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"
import Blog from "../components/Sections/Blog"

import AnimLaw from "../assets/animations/anim-law.json";

const content = `# Demarche Administratives. Guide Complet pour Faire Installer des Panneaux Solaires : 
---
L’installation de panneaux solaires photovoltaïques est une décision rentable à long terme, qui vous permet de réaliser des économies d’énergie tout en réduisant votre empreinte carbone.
Cependant, au-delà de l’aspect technique et financier, il est essentiel de bien comprendre les démarches administratives nécessaires pour réussir votre projet en toute conformité.
Dans cet article, nous vous guidons pas à pas à travers les démarches administratives requises en France pour installer des panneaux solaires, en vous expliquant également les **aides financières et subventions disponibles**.

### 1. **Pourquoi Installer des Panneaux Solaires ?**

Avant de plonger dans les démarches administratives,
 il est utile de rappeler les avantages de l’installation de panneaux photovoltaïques :
- **Réduction de la facture d’électricité :** Vous produisez votre propre électricité, réduisant ainsi votre dépendance au réseau.
- **Protéger contre la hausse des prix de l’électricité :** En autoproduisant votre énergie, vous vous mettez à l’abri des augmentations des tarifs d’électricité.
- **Un investissement rentable :** Les panneaux solaires vous permettent de réaliser d'importantes économies sur vos factures d’énergie.
Maintenant que ces avantages sont clairs, voyons les étapes administratives à suivre pour faire installer des panneaux solaires.

---
### 2. **Les Démarches Administratives pour Installer des Panneaux Solaires en France**

Pour installer des panneaux solaires en toute légalité, certaines démarches administratives doivent être effectuées.
Celles-ci varient en fonction de la taille de votre installation et de votre lieu de résidence.
  - **Étape 1 : Déclaration Préalable de Travaux (DP)**
Si vous souhaitez installer des panneaux photovoltaïques sur votre toit, vous devrez, dans la plupart des cas, effectuer une déclaration préalable de travaux (DP) auprès de votre mairie. Cette déclaration est obligatoire dans plusieurs situations, notamment si l’installation modifie l’aspect extérieur de votre maison.
  **Cas où la déclaration est obligatoire :**
    - **Modifications esthétiques :** Si votre installation modifie l’apparence extérieure de votre habitation.
    - **Sites protégés :** Si votre maison est située dans une zone protégée (site classé ou à proximité d'un monument historique), l'installation est soumise à l'approbation des Architectes des Bâtiments de France (ABF).
Démarche :
Remplir le formulaire Cerfa n°13703*07, disponible en ligne sur le site du Service Public.
Joindre un plan de situation du terrain et un plan de masse.
Attendre l’acceptation de la mairie, qui dispose d’un délai légal d’un mois pour répondre.
Source : Service-public.fr
  - **Étape 2 : Demande de Permis de Construire (dans certains cas)**
Si vous envisagez d'installer des panneaux solaires au sol ou si votre projet implique une installation de grande envergure, vous pourriez être soumis à une demande de permis de construire. Cette démarche est nécessaire pour les installations dépassant une certaine taille ou pour les projets qui modifient significativement la structure de la maison.
Cas où le permis est obligatoire :
Pour une installation au sol dépassant une hauteur de 1,80 m.
Si votre projet dépasse une puissance installée de 3 kWc dans certains secteurs protégés.
  **Démarche :**
    - Remplir le formulaire Cerfa n°13406*07 pour le permis de construire.
    - Fournir des documents comme les plans de coupe, l'insertion paysagère et la description des matériaux utilisés.
    - Le délai de traitement peut aller jusqu'à 3 mois selon la localisation du bien.
Source : Service-public.fr
  - **Étape 3 : Raccordement au Réseau Électrique**
Si vous optez pour une installation photovoltaïque avec vente du surplus ou si vous souhaitez être raccordé au réseau pour la revente totale de votre production, vous devrez effectuer une demande de raccordement au réseau électrique. Cette étape est gérée par Enedis, qui est le gestionnaire de réseau en France.
**Démarche :**
    - Faire une **demande de raccordement sur le site Enedis** en remplissant le formulaire dédié. Vous devrez fournir des informations sur votre installation, notamment la puissance en kWc et les détails techniques fournis par votre installateur.
    - Enedis étudie votre demande et vous envoie une proposition technique et financière (PTF) avec le devis de raccordement.
    - Après acceptation de cette proposition, Enedis procède au raccordement, généralement **dans un délai de 2 à 6 mois**, en fonction des spécificités de votre projet.
Source : Enedis.fr
  - **Étape 4 : Obligation d'Achat et Convention d'Autoconsommation**
Si vous choisissez de vendre l’excédent de votre production solaire à EDF (ou à tout autre fournisseur d’électricité habilité), vous devrez signer un contrat d’obligation d’achat (OA) avec EDF Obligation d'Achat.
**Démarche :**
    - Faire la demande en ligne via le portail dédié d’EDF OA Solaire.
    - Vous devrez fournir les informations techniques de votre installation et les certifications de votre installateur.
    - EDF OA procède ensuite à la mise en place du contrat et au versement annuel des recettes provenant de la vente de votre surplus.
Source : EDF OA Solaire
  - **Étape 5 : Assurance de l’Installation**
Il est également recommandé (et parfois obligatoire) d’**informer votre compagnie d’assurance de l’installation des panneaux solaires**. En cas de sinistre, il est important que votre installation soit bien couverte. Dans certains cas, il peut être nécessaire d’ajouter une clause spécifique pour les panneaux solaires dans votre contrat d’assurance habitation.
Démarche :
    - Contacter votre assureur pour l’informer de l'installation des panneaux solaires.
    - Vérifier que votre contrat couvre les éventuels dommages (incendie, intempéries, etc.) qui pourraient affecter les panneaux ou causer des dommages à autrui.

---
### 3. **Les Aides Financières pour Installer des Panneaux Solaires**

En France, plusieurs aides financières sont disponibles pour alléger le coût d'installation de panneaux solaires. Voici un aperçu des principales subventions et dispositifs de soutien.
  - **Prime à l’Autoconsommation**
La prime à l’autoconsommation est une aide versée par l'État aux ménages qui choisissent d'installer des panneaux solaires pour autoconsommer leur production d'électricité. Le montant de cette prime dépend de la puissance de l’installation :
    - 390 €/kWc pour une installation de 3 kWc.
    - 290 €/kWc pour une installation de 6 kWc.
Cette prime est versée sur 5 ans et peut représenter une réduction significative du coût global.
Source : Prime à l'autoconsommation - Ministère de la Transition écologique
  - **Le Chèque Énergie**
Le chèque énergie est une aide pour les ménages modestes qui peut être utilisée pour financer des travaux d'économie d'énergie, y compris l'installation de panneaux photovoltaïques. Le montant du chèque varie de 48 € à 277 €, selon les revenus du foyer.
Source : Chèque Énergie - Ministère de la Transition écologique
  - **TVA Réduite à 10 %**
Les installations solaires inférieures à 3 kWc bénéficient d’une TVA réduite à 10 %, contre 20 % pour les autres installations. Cela permet de réduire directement le coût d’installation.
Source : TVA réduite pour les énergies renouvelables - Impots.gouv.fr

---

# **Conclusion : Une Installation Solaire en Toute Légalité**
Faire installer des panneaux solaires nécessite de respecter certaines démarches administratives, mais ces formalités sont relativement complexes et peuvent être entièrement prise en charge par notre installateur.



`

export default function Article2() {
    return (
     <>
     <TopNavbar />
      <Blog content={content} anim={AnimLaw}/>
     <Footer />
     </>
    );
  }
  