import React from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import AnimatedNumbers from "react-animated-numbers";

// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";

// Assets
import ServicesAnimation from "../../assets/animations/anim-client-explain.json";

export default function Services() {

  const [num, setNum] = React.useState(798);
  return (
    <Wrapper id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Un calculateur précis à la pointe de la technologie.</h1>
            <p className="font13">
              <br></br>
            En quelques clics, obtenez une évaluation gratuite et précise de votre installation, dimensionnée pour maximiser vos économies et votre rendement.
           C'est 100% gratuit. Vous ne vous engagez à rien, mais nous nous engageons à vous fournir la meilleure solution pour votre projet.
            </p>
            {/* <AnimatedNumbers
              includeComma
              // className={styles.container}
              transitions={(index) => ({
                type: "spring",
                duration: index + 10,
              })}
              animateToNumber={num}
              fontStyle={{
                fontSize: 80,
                color: "black",
              }}
            /> */}
            <Lottie
                    animationData={ServicesAnimation}
                    style={{zIndex: 9, height: '700px'}}
                    // interactivity={interactivity}
          />
          <center>
           <p className="font13">
           La qualité des informations est essentielle pour réaliser une étude complète et sur mesure. Elle permet d'éviter le surdimensionnement fréquent des installations et garantit une solution parfaitement adaptée à vos besoins.
            </p>
            </center>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="gps"
                title="Analyse de votre toiture avec imagerie satellite"
                subtitle="
Notre simulateur utilise l’imagerie satellite pour analyser ombrage, pente, et orientation de votre toiture, assurant une simulation photovoltaïque optimale."
              />


            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="pvwatt"
                title="Simulation solaire détaillée pour votre domicile"
                subtitle="Notre logiciel simule avec précision la production solaire heure par heure en tenant compte des paramètres spécifiques de votre toiture."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="power"
                title="Intégration de la consommation via votre compteur Linky"
                subtitle="Nous utilisons les données de votre compteur Linky pour intégrer précisément votre consommation rendant notre simulateur unique et assurant un retour sur investissement optimisé."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox 
              icon="offer" 
              title="Offre personnalisée et partenaires sélectionnés" 
              subtitle="Nous avons sélectionné les meilleurs partenaires pour garantir la qualité de votre installation photovoltaïque. Obtenez un devis sur mesure avec des panneaux français, à prix compétitifs, et une garantie de 25 ans." />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
       
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: 50px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 30%;
  margin: 70px 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 60px 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;