import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import { MantineProvider, Text } from '@mantine/core';

import { Routes, Route, Outlet, Link } from "react-router-dom";

import TopNavbar from "./components/Nav/TopNavbar";
import Footer from "./components/Sections/Footer"
import Lottie from "lottie-react";
import anim404 from "./assets/animations/anim-404.json"
import animMaintenance from "./assets/animations/anim-maintenance.json"

import Blog from "./screens/Blog.jsx"

import Article1 from "./screens/Article1.jsx"
import Article2 from "./screens/Article2.jsx"
import Article3 from "./screens/Article3.jsx"
import Article4 from "./screens/Article4.jsx"
import Article5 from "./screens/Article5.jsx"
import Article6 from "./screens/Article6.jsx"

import CGU from "./screens/CGU.jsx"
import Cookies from "./screens/Cookies.jsx"

function NoMatch() {
  return (
   <>
   <TopNavbar />
   <div>
    <Lottie animationData={anim404} style={{zIndex: 9, height: '1000px', marginBottom: '200px'}}></Lottie>
    </div>
    <Footer />
    </>
  );
}

function UnderConstruction() {
  return (
    <>
    <TopNavbar />
    <div>

     <Lottie onClick={() => window.location = 'mailto:contact@mutualix.fr'} animationData={animMaintenance} style={{zIndex: 9, height: '1000px', marginBottom: '200px'}}></Lottie>
    </div>
    <Footer />
    </>
   );
}

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      {/* <Landing /> */}

      <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/article/combien-de-panneaux-solaires-installer" element={<Article1 />} />
          <Route path="/blog/article/les-demarches-administratives-installation-photovoltaique" element={<Article2 />} />
          <Route path="/blog/article/pourquoi-investir-installation-photovoltaique" element={<Article3 />} />
          <Route path="/blog/article/panneau-solaire-recyclage" element={<Article4 />} />
          <Route path="/blog/article/comment-ca-marche-un-panneau-photovoltaique" element={<Article5 />} />
          <Route path="/blog/article/avenir-transition-ecologique-france" element={<Article6 />} />

          <Route path="/legal/cgu" element={<CGU />} />
          <Route path="/legal/cookies" element={<Cookies />} />
          <Route path="/simulateur" element={<UnderConstruction />} />
          <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
