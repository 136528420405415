import React from "react";
import styled from "styled-components";
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// Assets
import LogoIcon from "../../assets/logo/icononly-nobuffer.png";

const ImgStyle = styled.img`
  // width: 40%;
  height: 50px; // Ensure the aspect ratio of the image is maintained

`;

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }
  const url = window.location.pathname;

  return (
    <Wrapper>
      <div className="darkBg" style={{zIndex: 20}}>
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "20px 0" }}>
            <RouterLink className="flexCenter animate pointer" to="/" smooth={true} offset={-80}>
            <ImgStyle src={LogoIcon} alt="client logo" />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Mutualix, Better together!
              </h1>
            </RouterLink>
           <center>

            <RouterLink to="/legal/cookies" className="flexCenter footer-link">
                Politique d'utilisation des cookies
              </RouterLink>
              <span style={{color: "white"}}>-</span>

            <RouterLink to="/legal/cgu" className="flexCenter footer-link">
                Conditions d'utilisation
              </RouterLink>
              <span style={{color: "white"}}>-</span>
            
              <StyleP className="whiteColor">
              © {getCurrentYear()} <span className="purpleColor">Mutualix SAS</span>
              </StyleP>

           </center>

            { url === '/' ? 
            <Link className="whiteColor animate pointer font13 semiBold" to="home" smooth={true} offset={-80}>
              Back to top ︽
            </Link> :  
             <div className="font13 semiBold" to="home" smooth={true} offset={-80} style={{color: "transparent"}}>
             Back to top ︽
           </div> }
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
    justify-content: center;
  align-items: center;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;