import React, { useState } from 'react';
import styled from "styled-components";
import Lottie from "lottie-react";
import Markdown from 'react-markdown';
import FullButton from "../Buttons/FullButton";
// import remarkGfm from 'remark-gfm';
import classes from './ArticleBox.module.css';


export default function ArticleBox({ link, title, text, anim }) {
  return (
    // <WrapperBtn className="animate pointer" onClick={action ? () => action() : null}>
      <Wrapper className="whiteBg radius8 shadow" style={{fontFamily: "Futura PT"}}>
      <center>

        <h3 className="font20 extraBold">{title}</h3>

        {(anim) ? <Lottie animationData={anim} style={{zIndex: 9, height: '200px', margin: '30px'}}></Lottie> : null}
        <p style={{ padding: "30px 10px", lineHeight: "20px", fontSize: "13pt"}}>
          <Markdown>{text}</Markdown>
        </p>
        <a href={link} className="btn font13 semiBold" style={{backgroundColor: "#00babc", color: "white", borderColor: "lightgrey", border: "0px", padding: "10px", borderRadius: "1rem", textAlign: "center", verticalAlign:"center"}}>Lire l'article complet</a>
        </center>


      </Wrapper>
    // </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;
