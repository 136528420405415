import React from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"
import Blog from "../components/Sections/Blog"
import Lottie from "lottie-react";


const content = `
# Conditions Générales d'Utilisation (CGU)

---

## 1. Présentation du Site

Le site **mutualix.com** (ci-après dénommé le "Site") est édité par la société **Mutualix SASU**, société par actions simplifiée unipersonnelle (SASU), au capital de **1000 €**, dont le siège social est situé à **119 Route de le Mairie, 74500 BERNEX, FRANCE**, immatriculée au Registre du Commerce et des Sociétés de **THONON-LES-BAINS** sous le numéro **932777246**.

Le Site propose aux utilisateurs un simulateur photovoltaïque permettant d'évaluer la rentabilité d'une installation solaire, basé sur les données de consommation énergétique et les caractéristiques des toitures des utilisateurs (ci-après dénommés les "Services").

---

## 2. Acceptation des CGU

L’accès au Site et son utilisation sont subordonnés à l’acceptation sans réserve des présentes **Conditions Générales d'Utilisation (CGU)**. En accédant ou en utilisant le Site, vous reconnaissez avoir pris connaissance de ces CGU et acceptez de vous y conformer.

Mutualix se réserve le droit de modifier à tout moment les CGU. Les modifications seront applicables dès leur mise en ligne sur le Site. Il est recommandé de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.

---

## 3. Accès et Utilisation du Site

Le Site est accessible gratuitement à tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour accéder aux Services (matériel informatique, connexion Internet, etc.) sont à la charge de l’utilisateur.

L'utilisation du simulateur photovoltaïque nécessite de fournir certaines informations personnelles et des données de consommation énergétique pour générer une estimation de la rentabilité de l'installation photovoltaïque. L'utilisateur s'engage à fournir des informations exactes et à jour.

Le simulateur fournit une estimation indicative et non contractuelle des économies et rendements potentiels d’une installation photovoltaïque.

---

## 4. Propriété Intellectuelle

Tous les éléments du Site (contenus, graphiques, logos, marques, etc.) sont la propriété exclusive de **Mutualix** ou de ses partenaires, et sont protégés par le droit d’auteur et le droit des marques. Toute reproduction, distribution, modification ou exploitation des contenus du Site sans l’autorisation préalable écrite de Mutualix est strictement interdite.

---

## 5. Responsabilité de Mutualix

Mutualix met en œuvre tous les moyens raisonnables pour assurer la disponibilité et le bon fonctionnement du Site et des Services. Toutefois, Mutualix ne peut garantir que le Site sera accessible en permanence, sans interruption, ou exempt d’erreurs.

Mutualix décline toute responsabilité en cas de dommage direct ou indirect lié à l’utilisation du Site, notamment en raison de :

- l’indisponibilité du Site ;
- des erreurs dans les résultats obtenus via le simulateur photovoltaïque ;
- la fourniture de données inexactes par l’utilisateur.

Le simulateur proposé sur le Site est un outil de calcul estimatif basé sur les données fournies par l’utilisateur et sur des hypothèses techniques. Il ne constitue en aucun cas une offre ferme ou un engagement contractuel de la part de Mutualix ou de ses partenaires.

---

## 6. Obligations de l'Utilisateur

L’utilisateur s’engage à utiliser le Site conformément aux lois et règlements en vigueur, ainsi qu’aux présentes CGU. Il s’interdit notamment de :

- Usurper l’identité d’une autre personne ;
- Fournir des informations fausses ou trompeuses ;
- Perturber le fonctionnement du Site ou tenter d'accéder au système informatique de Mutualix.

---

## 7. Protection des Données Personnelles (RGPD)

Mutualix attache une importance particulière à la protection des données personnelles et s’engage à respecter la réglementation en vigueur, notamment le **Règlement Général sur la Protection des Données (RGPD)**.

### 7.1. Données Collectées

Dans le cadre de l’utilisation du simulateur photovoltaïque, Mutualix est amenée à collecter les données suivantes :

- **Données personnelles** : nom, prénom, adresse e-mail, numéro de téléphone (le cas échéant, pour le contact avec les partenaires régionaux).
- **Données techniques** : consommation énergétique, caractéristiques de la toiture (surface, orientation, inclinaison).

Ces données sont collectées dans le but de fournir une estimation de la rentabilité de l’installation photovoltaïque et de permettre à Mutualix de contacter les utilisateurs en vue de leur proposer des services adaptés.

### 7.2. Finalités de Traitement des Données

Les données personnelles collectées sont utilisées pour :

- Fournir le service de simulation photovoltaïque ;
- Vous informer des résultats de la simulation et vous proposer des offres commerciales ;
- Améliorer les Services proposés sur le Site.

### 7.3. Partage des Données

Les données collectées peuvent être partagées avec des partenaires régionaux de Mutualix pour proposer une offre adaptée à l’utilisateur. Ce partage se fait avec le consentement explicite de l’utilisateur, qui peut refuser à tout moment en contactant Mutualix.

### 7.4. Droits de l'Utilisateur

Conformément au RGPD, l’utilisateur dispose des droits suivants sur ses données :

- **Droit d’accès** : obtenir une copie des données personnelles détenues par Mutualix.
- **Droit de rectification** : demander la correction des informations inexactes.
- **Droit à l’effacement** : demander la suppression des données dans certaines circonstances.
- **Droit d’opposition** : s’opposer à l’utilisation de ses données à des fins de prospection.
- **Droit à la portabilité** : recevoir ses données dans un format structuré.

Pour exercer ces droits, l’utilisateur peut contacter Mutualix par e-mail à l’adresse suivante : contact@mutualix.fr.

### 7.5. Conservation des Données

Les données personnelles sont conservées pendant la durée strictement nécessaire à la réalisation des finalités mentionnées ci-dessus. Les données peuvent être conservées pour une période maximale de 3 ans après la fin de la relation commerciale pour des actions de prospection commerciale, sauf si l’utilisateur s’y oppose.

---

## 8. Cookies

Le Site utilise des cookies pour améliorer l’expérience utilisateur et analyser la fréquentation du Site. L’utilisateur peut à tout moment configurer son navigateur pour désactiver les cookies ou être informé de leur utilisation. Cependant, certaines fonctionnalités du Site peuvent être altérées en cas de désactivation des cookies.

Pour plus d'informations, consultez notre **Politique de Cookies** disponible sur le Site.

---

## 9. Droit Applicable et Juridiction Compétente

Les présentes CGU sont régies par le droit français. En cas de litige, et à défaut de résolution amiable, les tribunaux compétents seront ceux de **THONON-LES-BAINS**.

---

## 10. Contact

Pour toute question relative à l’utilisation du Site ou à la gestion de vos données personnelles, vous pouvez nous contacter à l’adresse suivante : contact@mutualix.fr ou par courrier à Mutualix SAS, 119 Route de la Mairie, 74500 BERNEX, FRANCE.

`

export default function CGU() {
    return (
     <>
     <TopNavbar />
      <Blog content={content}/>
     <Footer />
     </>
    );
  }
  