import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/logo/icononly-nobuffer.png";



export default function Sidebar({ sidebarOpen, toggleSidebar }) {

  const url = window.location.pathname;

  var menuContent = (<></>) 
  
  
  if (url === '/')
    menuContent = (<>
    <UlStyle className="flexNullCenter flexColumn">
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="home"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Home
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="services"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Notre calculateur
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="blog"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Infos utiles
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="pricing"
              spy={true}
              smooth={true}
              offset={-60}
            >
              FAQ
            </Link>
          </li>

          <li className="semiBold font15 pointer">
            <RouterLink
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="/blog"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Blog
            </RouterLink>
          </li>

        </UlStyle>
  
        </>
  );
  else if ( url.includes('legal') )
    menuContent = (<>
      <UlStyle className="flexNullCenter flexColumn">
            <li className="semiBold font15 pointer">
              <RouterLink
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                className="whiteColor"
                style={{ padding: "10px 15px" }}
                to="/"
                spy={true}
                smooth={true}
                offset={-60}
              >
                Home
              </RouterLink>
            </li>

            <li className="semiBold font15 pointer">
              <RouterLink
                onClick={() => toggleSidebar(!sidebarOpen)}
                activeClass="active"
                className="whiteColor"
                style={{ padding: "10px 15px" }}
                to="/blog"
                spy={true}
                smooth={true}
                offset={-60}
              >
                Blog
              </RouterLink>
            </li>
  
          </UlStyle>
    
          </>
    );
    else
      menuContent = (<>
        <UlStyle className="flexNullCenter flexColumn">
              <li className="semiBold font15 pointer">
                <RouterLink
                  onClick={() => toggleSidebar(!sidebarOpen)}
                  activeClass="active"
                  className="whiteColor"
                  style={{ padding: "10px 15px" }}
                  to="/"
                  spy={true}
                  smooth={true}
                  offset={-60}
                >
                  Home
                </RouterLink>
              </li>
    
              <li className="semiBold font15 pointer">
                <RouterLink
                  onClick={() => toggleSidebar(!sidebarOpen)}
                  activeClass="active"
                  className="whiteColor"
                  style={{ padding: "10px 15px" }}
                  to="/blog"
                  spy={true}
                  smooth={true}
                  offset={-60}
                >
                  BlogIndex
                </RouterLink>
              </li>
    
            </UlStyle>
      
            </>
      );

  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          {/* <LogoIcon /> */}
          <ImgStyle src={LogoIcon} alt="client logo" />
          <h1 className="whiteColor font20 extraBold" style={{ marginLeft: "15px" }}>
            Mutualix
          </h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      {menuContent}

      <UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer flexCenter">
          <a href="/simulateur" className="radius8 lightBg" style={{ padding: "10px 20px" }}>
            Lancer une simulation
          </a>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const ImgStyle = styled.img`
  // width: 40%;
  height: 100px; //
  
   Ensure the aspect ratio of the image is maintained
  padding-bottom: 15%;
  padding-top: 15%;
  // box-sizing: border-box; // Include padding in the element's total width and height
`;

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
