import React from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"
import Blog from "../components/Sections/Blog"
import Lottie from "lottie-react";


const content = `
# Politique d'Utilisation des Cookies

---

## Introduction

Le site **Mutualix.com** (ci-après "le Site") utilise des cookies pour améliorer l'expérience de l'utilisateur, analyser le trafic du Site, et offrir des services et publicités personnalisés. En utilisant le Site, vous consentez à l'utilisation de cookies conformément à la présente **Politique d'Utilisation des Cookies**.

---

## Qu'est-ce qu'un Cookie ? 🍪

Un cookie est un petit fichier texte enregistré sur votre appareil (ordinateur, tablette, smartphone) lorsque vous visitez un site internet. Les cookies permettent à un site de reconnaître votre appareil, de mémoriser vos préférences de navigation, et d'améliorer votre expérience en ligne.

---

## Types de Cookies Utilisés

Nous utilisons différents types de cookies sur notre Site :

### 1. Cookies Strictement Nécessaires

Ces cookies sont indispensables pour naviguer sur le Site et utiliser ses fonctionnalités. Ils ne collectent aucune information personnelle et ne peuvent pas être désactivés dans nos systèmes. Ils sont généralement définis en réponse à des actions que vous avez effectuées, telles que la définition de vos préférences de confidentialité ou l'accès à des zones sécurisées du Site.

### 2. Cookies de Performance

Ces cookies nous permettent de compter les visites et les sources de trafic afin de mesurer et d'améliorer les performances du Site. Ils nous aident à savoir quelles pages sont les plus et les moins populaires et à voir comment les visiteurs naviguent sur le Site. Toutes les informations collectées par ces cookies sont agrégées et donc anonymes.

### 3. Cookies de Fonctionnalité

Ces cookies permettent au Site de fournir des fonctionnalités améliorées et une personnalisation. Ils peuvent être définis par nous ou par des fournisseurs tiers dont nous avons ajouté les services à nos pages. Si vous n'autorisez pas ces cookies, certains ou tous ces services pourraient ne pas fonctionner correctement.

### 4. Cookies de Ciblage

Ces cookies peuvent être définis sur notre Site par nos partenaires publicitaires. Ils peuvent être utilisés par ces sociétés pour établir un profil de vos intérêts et vous montrer des annonces pertinentes sur d'autres sites. Ils ne stockent pas directement des informations personnelles mais se basent sur l'identification unique de votre navigateur et de votre appareil internet.

---

## Comment Gérer vos Préférences de Cookies ?

Vous pouvez configurer votre navigateur pour refuser tous les cookies ou pour vous alerter lorsqu'un cookie est déposé. Cependant, certaines fonctionnalités du Site pourraient ne pas fonctionner correctement sans cookies. Pour plus d'informations sur la gestion des cookies, veuillez consulter la section d'aide de votre navigateur.

---

## Modifications de la Politique d'Utilisation des Cookies

Nous nous réservons le droit de modifier la présente politique de cookies à tout moment. Toute modification sera publiée sur cette page et, le cas échéant, vous en serez informé par e-mail.

---

## Contact

Pour toute question relative à notre politique d'utilisation des cookies, vous pouvez nous contacter à l'adresse suivante :

**Adresse e-mail** : [contact@mutualix.fr](mailto:contact@mutualix.fr)

**Adresse postale** : Mutualix SASU, 119 route de la mairie, 74500 Bernex, France

`

export default function Cookies() {
    return (
     <>
     <TopNavbar />
      <Blog content={content}/>
     <Footer />
     </>
    );
  }
  