import React from "react";
import styled from "styled-components";
// Components
import AddImage1 from "../../assets/images/zolno.jpeg";
import AddImage2 from  "../../assets/images/jpeg.jpeg";
import FullButton from "../Buttons/FullButton";
export default function Pricing() {
  return (
    <Wrapper id="pricing">
       <div className="whiteBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font15 semiBold">Quelques mots sur nous</h4>
                <h2 className="font40 extraBold">"Stay hungry, Stay foolish"</h2>
                <br />
                <p className="font12">
                Nous sommes une équipe dynamique, pleine de talents unis par un objectif commun : participer au changement. Notre mission est de réconcilier économie et écologie, et la bonne nouvelle, c'est que c'est possible grâce au solaire photovoltaïque.
                Pour réaliser ce projet, nous avons réuni des ingénieurs en informatique et en énergies renouvelables. Avec Mutualix, nous contribuons activement à cette transformation.
                Rejoignez-nous et passez à l'action !
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  {/* <div style={{ width: "190px" }}>
                    <FullButton title="Rejoins nous !" action={() => alert("clicked")} />
                  </div> */}
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Plus d'infos" action={() => alert("clicked")} border />
                  </div>
                </ButtonsRow>
              </AddLeft>
        

           
              <AddRight>
                <AddRightInner>
                <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office"  style={{width: "90px", marginBottom: "45px"}}/>

                    </AddImgWrapp1>
                    <p>
                          <h1>
                          Stéphane ZUBROWKA
                          </h1>
                          CEO Fondateur
                          <br></br>
                          <br></br>
                          <h5>
                          Ingénieur en énergies renouvelables, anciennement ingénieur conseils d'une entreprise leader du secteur de la technologie du bâtiment
                          </h5>
                      </p>
                    </div>
                  </AddRightInner>
                  <AddRightInner>
                  <div className="flexNullCenter" style={{marginTop: "50px"}}>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" style={{width: "90px", marginBottom: "45px"}} />
                    </AddImgWrapp2>
                    <p>
                          <h1>
                          Jérémy PEGUET
                          </h1>
                          CTO Fondateur
                          <br></br>
                          <br></br>
                          <h5>
                          Architecte en technologie numérique, anciennement directeur technique d'une école d'informatique supérieure de renomée mondiale
                          </h5>
                      </p>
                    </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: 50px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 30%;
  margin: 70px 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 60px 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 5rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;


