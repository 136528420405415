import React from "react";
import styled from "styled-components";

// Assets
import Markdown from 'react-markdown';
import Lottie from "lottie-react";


export default function Blog({ content, anim }) {
  return (



    <Wrapper id="blog">

<li className="semiBold font15 pointer flexCenter mobileOnly">
    <a href="/simulateur" className="radius8 lightBg" style={{ padding: "10px 15px", backgroundColor: "#00BABC", color: "white", position: "fixed", bottom: "50px", zIndex: 10}}>
      Lancer une simulation 🚀
    </a>
  </li>

      <div className="lightBg">
        <div className="container">
        <HeaderInfo>
          <center>
          {(anim) ? <Lottie animationData={anim} style={{zIndex: 9, height: '500px', margin: '30px'}}></Lottie> : null}
          </center>
         
        </HeaderInfo>
      
          <MdWrap>
            <Markdown className={'prose'} children={content}></Markdown>
          </MdWrap>
          <HeaderInfo>

          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const MdWrap = styled.section`
  white-space: pre-wrap;
`

const Wrapper = styled.section`
  width: 100%;
  padding-top: 100px;
  margin-bottom: 0px;
`;


const HeaderInfo = styled.div`
  margin-bottom: 30px;
 padding-top: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

