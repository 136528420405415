import React from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"
import Blog from "../components/Sections/Blog"

import AnimTeacher from "../assets/animations/anim-teacher.json";

const content = `# Comment ça Marche un Panneau Photovoltaïque ? 
---

Les panneaux photovoltaïques sont devenus un élément clé de la transition énergétique, permettant de produire de l’électricité à partir de la lumière du soleil. **Mais comment fonctionnent exactement ces panneaux solaires ?**
Quels sont les principes physiques à l'œuvre et comment sont-ils conçus pour capter l'énergie solaire et la transformer en électricité utilisable dans nos maisons ? Dans cet article, nous allons décortiquer le fonctionnement d’un panneau photovoltaïque en détail.

### **1. Qu'est-ce qu'un Panneau Photovoltaïque ?**

Un panneau photovoltaïque est un dispositif conçu pour convertir la lumière du soleil en électricité.
Cette électricité est produite par un effet physique appelé l'effet photovoltaïque, **découvert par le physicien français Edmond Becquerel** en 1839.
Grâce à ce processus, les panneaux solaires transforment directement l’énergie lumineuse en électricité, **sans aucune pièce mobile, ce qui en fait une technologie durable et fiable.**
Un panneau photovoltaïque est composé de plusieurs cellules solaires, qui sont les unités de base où se déroule la conversion de la lumière en électricité.

---
### **2. Le Principe Physique des Panneaux Solaires : L’Effet Photovoltaïque**

L'effet photovoltaïque est le phénomène par lequel un matériau semi-conducteur, comme le silicium, génère un courant électrique lorsqu'il est exposé à la lumière du soleil.
  - **Étape 1 : L'absorption des photons solaires**
Lorsque la lumière du soleil (composée de particules appelées photons) frappe la surface d’une cellule solaire, les photons transmettent leur énergie aux électrons présents dans le matériau semi-conducteur de la cellule (généralement du silicium).
  - **Étape 2 : La libération des électrons**
Sous l’effet de l’énergie des photons, certains électrons de la cellule solaire acquièrent suffisamment d'énergie pour se détacher de leur position dans le matériau. Cette libération d’électrons crée des trous d’électrons dans la structure atomique, qui circulent dans le matériau.
  - **Étape 3 : La création du courant électrique**
Pour que ce déplacement des électrons puisse générer un courant électrique, la cellule photovoltaïque comporte une jonction PN (une zone qui sépare deux types de silicium : le silicium de type P, riche en trous, et le silicium de type N, riche en électrons).
Cette jonction permet de diriger le mouvement des électrons vers un circuit électrique extérieur, créant ainsi un courant continu (DC).

---
### **3. Conception d'un Panneau Photovoltaïque : Les Différents Composants**

Un panneau solaire est constitué de plusieurs cellules solaires interconnectées pour former un module capable de générer suffisamment d’électricité pour des applications pratiques.
Voici les principaux composants d’un panneau photovoltaïque.
  - **Les Cellules Photovoltaïques**
Chaque panneau contient plusieurs cellules photovoltaïques (généralement entre 60 et 72 cellules par panneau). Ces cellules sont fabriquées principalement à partir de silicium et sont responsables de la conversion de la lumière en électricité.
Il existe deux principaux types de cellules solaires :
    - **Les cellules monocristallines** : Elles sont fabriquées à partir d’un cristal de silicium unique et sont plus efficaces que les cellules polycristallines, avec un rendement qui peut atteindre jusqu'à 20 %.
    - **Les cellules polycristallines** : Elles sont fabriquées à partir de plusieurs cristaux de silicium. Elles sont moins coûteuses à produire, mais leur rendement est généralement inférieur à celui des cellules monocristallines (environ 15 à 18 %).
  - **Le Verre Protecteur**
Les cellules solaires sont recouvertes d'un verre trempé qui permet de protéger les cellules tout en laissant passer un maximum de lumière solaire. Ce verre est conçu pour résister aux intempéries et aux chocs, garantissant ainsi une longue durée de vie du panneau.
  - **Le Cadre**
Les panneaux solaires sont encadrés par une structure en aluminium qui leur donne rigidité et robustesse. Ce cadre permet également de faciliter l’installation des panneaux sur des structures de support, qu’il s’agisse de toits ou d’installations au sol.
  - **Le Boîtier de Connexion et le Circuit Électrique**
À l’arrière du panneau se trouve un boîtier de connexion, qui permet de relier les panneaux entre eux ou de les connecter à un onduleur. Ce boîtier assure la bonne circulation du courant électrique vers le circuit extérieur.

---
### **4. Transformation du Courant Continu (DC) en Courant Alternatif (AC)**

Les panneaux solaires génèrent de l’électricité sous forme de courant continu (DC). Or, les appareils électriques de la maison, ainsi que le réseau électrique, fonctionnent avec du courant alternatif (AC). C’est là qu’intervient un autre composant clé de l’installation photovoltaïque : l’onduleur.

**Le rôle de l’onduleur**
L’onduleur a pour rôle de convertir le courant continu (DC) produit par les panneaux solaires en courant alternatif (AC), compatible avec les appareils électriques de votre maison et le réseau électrique. Il assure également la régulation de la tension et de la fréquence du courant pour qu’il soit adapté aux normes du réseau.
  - **Types d'onduleurs**
Il existe plusieurs types d’onduleurs selon les configurations :
    - Onduleur centralisé : Utilisé pour les grandes installations, il convertit l’électricité de plusieurs panneaux solaires à la fois.
    - Micro-onduleur : Chaque panneau solaire est équipé de son propre onduleur, ce qui permet d’optimiser la production de chaque panneau indépendamment.     

---
### **5. Autoconsommation et Vente de Surplus : Utilisation de l’Électricité Produite**
Une fois l’électricité produite et convertie, elle peut être utilisée de deux manières :
  - **Autoconsommation** : L’électricité produite est utilisée directement pour alimenter les appareils électriques de votre maison, ce qui réduit votre dépendance au réseau.
  - **Vente du surplus** : Si la production d’électricité dépasse vos besoins, l’excédent peut être vendu au réseau électrique. En France, cette vente est régulée par un contrat avec EDF OA Solaire, qui rachète l’électricité à un tarif fixé.
---
# **Conclusion : Une Technologie Efficace et Durable** 
Le fonctionnement d’un panneau photovoltaïque repose sur un principe physique simple, mais puissant : l'effet photovoltaïque. Grâce à ce processus, la lumière du soleil est convertie en électricité de manière propre et durable. Bien que le cœur de la technologie repose sur des cellules photovoltaïques en silicium, chaque composant du panneau joue un rôle crucial dans l’efficacité et la longévité de l’installation.
Si vous souhaitez maximiser vos économies d’énergie tout en réduisant votre empreinte carbone, l’installation de panneaux solaires est une solution rentable et respectueuse de l’environnement. Pour en savoir plus sur la rentabilité de votre projet solaire, n’hésitez pas à utiliser un simulateur solaire comme celui proposé par Mutualix, qui vous aide à estimer les économies potentielles et les subventions disponibles.

`

export default function Article5() {
    return (
     <>
     <TopNavbar />
      <Blog content={content} anim={AnimTeacher}/>
     <Footer />
     </>
    );
  }
  