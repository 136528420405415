import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Infos from "../components/Sections/Infos";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Faq from "../components/Sections/Faq";
import Footer from "../components/Sections/Footer"
import { MantineProvider} from '@mantine/core';

import { Routes, Route, Outlet, Link } from "react-router-dom";
export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Infos />
      <Pricing />
      {/* <Contact /> */}
      <MantineProvider withNormalizeCSS>
        <Faq />
      </MantineProvider>
      <Footer />
    </>
  );
}


