import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

import AnimTaxes from "../../assets/animations/anim-taxes.json"; 
import AnimMoney from "../../assets/animations/anim-money.json";
import AnimTime from "../../assets/animations/anim-lifespan.json";
import AnimRecycle from "../../assets/animations/anim-recycle.json";
import AnimCO2 from "../../assets/animations/anim-carbon-foot.json";
import AnimPrice from "../../assets/animations/anim-cart.json";



const box1 = `
---
Il existe deux subventions pour le photovoltaïque:
  - **Prime liée à l'installation** et à sa puissance :
    - **260 €/kWc pour une installation de 3kWc** ou moins.
    - **190 €/kWc pour une installation allant jusqu'à 9 kWc.**
  - **Obligation d'achat sur 20 ans :** L'énergie produite sans être consommée est **revendue à 13 cts/kWh**, soit environ la moitié du prix de l'énergie achetée. Cela permet d'améliorer la rentabilité, mais il est important de ne pas surproduire pour consommer au maximum l'énergie issue de la production solaire.

[Plus d'informations en cliquant ici !](https://www.economie.gouv.fr/particuliers/aides-installation-photovoltaiques)
`

const box2 = `
---
Les panneaux solaires proposés par Mutualix et ses partenaires bénéficient d'une **garantie de 25 ans** et **100% français**, couvrant à la fois les **pièces et la main-d'œuvre.** Vous pouvez être tranquille, notre matériel est d'une fiabilité éprouvée.

La durée de vie de l'installation va bien au-delà de cette garantie : certaines installations, comme la centrale de Phébus 1, fonctionnent depuis plus de 30 ans. Cette installation pionnière célèbre aujourd'hui ses 32 ans de service.

En cas de tempête ou d'épisode de grêle exceptionnel, ne vous inquiétez pas : votre **installation solaire est protégée par votre assurance habitation.**
`
const box3 = `
---
Avec l'essor des installations photovoltaïques, le recyclage a connu un certain retard.
Cependant, ce retard est désormais comblé grâce à l'implantation de **nombreuses structures de recyclage à travers le pays.**

L'État a investi massivement pour soutenir ces initiatives, rendant le recyclage des panneaux solaires non seulement possible, mais aussi efficace.

Aujourd'hui, le verre, le cadre en aluminium, et de nombreux autres composants des panneaux sont recyclés.
Même la collecte des panneaux en fin de vie est organisée et prise en charge. **Le taux de recyclabilité a atteint l'impressionnant chiffre de 95 %.**

[Plus d'informations en cliquant ici !](https://www.soren.eco/?gad_source=1&gclid=CjwKCAjwlbu2BhA3EiwA3yXyu_RBOmGXR3erT4t-miGKVQvnIJKsB95eedvCcqvtYTJ0R7VgQfcJYBoCnxIQAvD_BwE)

`
const box4 = `
---
Comme tout processus industriel, la fabrication, le transport et l'installation des panneaux solaires nécessitent de l'énergie.
Une partie de cette énergie est d'origine carbonée.
Selon l'ADEME (Agence de la transition écologique),
**il faut 3 ans pour qu'un panneau solaire compense les émissions de CO2 générées lors de sa production.**
Autrement dit, en 3 ans, le panneau a économisé autant de CO2 qu'il en a émis au départ.

Étant donné qu'un panneau solaire reste fonctionnel pendant environ 30 ans,
**son bilan carbone est non seulement neutralisé, mais devient largement positif** sur le long terme.

[Plus d'informations en cliquant ici !](https://www.photovoltaique.info/fr/info-ou-intox/les-enjeux-environnementaux/temps-de-retour-energetique/)

Si vous avez 10min, faites le test suivant pour connaître votre empreinte carbone personnelle.
Vous risquez d’être étonné …

[Cliquez ici pour faire le test !](https://nosgestesclimat.fr/tutoriel)

`
const box5 = `
---
Le photovoltaïque présente plusieurs avantages fiscaux intéressants :
- Petites installations (**moins de 3 kWc**) :
  - **TVA réduite à 10 %.**
  - **Revenus** issus de la revente d'électricité **entièrement défiscalisés.**
- Installations de **plus de 3 kWc** :
  - TVA à 20 %.
  - **Abattement fiscal de 71 % sur les revenus de la revente**,
  vous laissant seulement 29 % à déclarer, selon le taux d'imposition de votre tranche finale.\

Ces avantages **permettent de maximiser les bénéfices financiers** de votre installation photovoltaïque tout en réduisant vos obligations fiscales.

`
const box6 = `
---
Le prix de l'électricité, bien que réglementé, a fortement augmenté ces dernières années.
**En 15 ans, le tarif a grimpé de 10 cts/kWh à 25 cts/kWh. Une augmentation de 150%**,
comme le montre ce graphique [ici](https://www.fournisseurs-electricite.com/contrat-electricite/prix/evolution).\

Cette tendance ne montre aucun signe de ralentissement : les prix devraient dépasser **35 cts/kWh avant 2030.**
Face à cette inflation continue, le solaire photovoltaïque se présente comme une solution efficace.

En effet, plus les prix de l'électricité augmentent,
plus votre installation solaire devient rentable, vous offrant une protection durable contre l'augmentation des coûts énergétiques.

On sait aussi que le prix de l'électricité est contenu par les mesures nationales, comme le bouclier tarifaire, qui, malheureusement, est voué à disparaître.
**Alors, agissez et optez pour votre propre bouclier, le bouclier solaire.**
`


export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Les points forts du photovoltaïque.</h1>
            <p className="font13">
              <br />
              Découvrez tous les avantages de l'énergie solaire ! Économies sur vos factures, indépendance énergétique, réduction de votre empreinte carbone… Le photovoltaïque, c’est tout ça et bien plus. Explorez les bénéfices concrets pour votre maison et votre avenir.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Des subventions pour tous"
                text={box1}
                anim={AnimMoney}
                // action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Panneaux 100% français garantis 25 ans"
                text={box2}
                anim={AnimTime}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Panneaux recyclables à 95%"
                text={box3}
                anim={AnimRecycle}
                // tag="company"
                // tag="company"
                // author="Luke Skywalker, 2 days ago"
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Bilan carbone positif pour votre installation"
                text={box4}
                // tag="company"
                // author="Luke Skywalker, 2 days ago"
                anim={AnimCO2}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Plusieurs avantages fiscaux existent"
                text={box5}
                anim={AnimTaxes}
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title="Protégez-vous contre l'explosion du prix de l'énergie grâce au bouclier solaire !"
                text={box6}
                // tag="company" https://www.fournisseurs-electricite.com/contrat-electricite/prix/evolution
                // author="Luke Skywalker, 2 days ago"
                anim={AnimPrice}
                action={() => alert("clicked")}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Ils l'ont fait, pourquoi pas vous ? 😎</h1>
            <p className="font13">
              <br />
              Qui de mieux que nos clients pour parler de nous !
              <br />

              Chez Mutualix, nous croyons que nos clients sont nos meilleurs ambassadeurs. Leur satisfaction et leurs témoignages parlent d'eux-mêmes. Découvrez pourquoi ils nous font confiance pour leurs installations photovoltaïques et comment nos solutions ont transformé leur quotidien.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;