import React, { useState } from 'react';
import styled from "styled-components";
import Lottie from "lottie-react";
import Markdown from 'react-markdown';
import FullButton from "../Buttons/FullButton";
// import remarkGfm from 'remark-gfm';
import classes from './BlogBox.module.css';


const Popup = ({ anim, title, text, onClose }) => {
  return (
    <div className={classes.popupcontainer}>
      <div className={classes.popup}>


      <div className="row">
      <div className="col-xs-9 col-sm-6 col-md-6 col-lg-6 textLeft">
      <h2 className="extraBold">{title}</h2>
        </div>
          <div className="col-xs-3 col-sm-6 col-md-6 col-lg-6 textRight">
          <button className="btn btn-success radius6 font14 semiBold" onClick={onClose} style={{backgroundColor: "transparent", color: "black", borderColor: "lightgrey", border: "0px", height: "30px"}}>✖</button>
        </div>
      </div >

        {/* <p style={{ padding: "30px 10px", lineHeight: "20px", fontSize: "13pt"}}> */}
        <MdWrap>
            <Markdown className={'prose'} children={text}></Markdown>
          </MdWrap>
        {/* </p> */}
      
      </div>
    </div>
  );
};

export default function BlogBox({ tag, title, text, action, author, anim }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  return (
    // <WrapperBtn className="animate pointer" onClick={action ? () => action() : null}>
      <Wrapper className="whiteBg radius8 shadow" style={{fontFamily: "Futura PT"}}>
      <center>

        <h3 className="font20 extraBold">{title}</h3>
        
        {(anim) ? <Lottie animationData={anim} style={{zIndex: 9, height: '200px', margin: '30px'}}></Lottie> : null}
      </center>
       
      <div>

      {/* <FullButton title="Nous contacter" action={() => openPopup()} border /> */}
      <button className="btn font200 extraBold shadow" onClick={openPopup} style={{backgroundColor: "#EEEEEE", color: "#00babc", borderColor: "lightgrey", border: "0px", marginLeft: "90%", borderRadius: "20%", height: "40px", width: "40px", textAlign: "center", verticalAlign:"center", boxShadow: "2px"}}>+</button>
      {isPopupOpen && <Popup anim={anim} title={title} text={text} onClose={closePopup} />}
    </div>

       
      </Wrapper>
    // </WrapperBtn>
  );
}

const MdWrap = styled.section`
  white-space: pre-wrap;
`

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;
